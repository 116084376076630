import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
export const AsideHead = ({ children }) => {
    return <div className='aside-head'>{children}</div>;
};
export const AsideBody = ({ children }) => {
    return <div className='aside-body'>{children}</div>;
};
export const AsideFoot = ({ children }) => {
    return <div className='aside-foot'>{children}</div>;
};
const Aside = ({ children }) => {
    const { asideStatus } = useContext(ThemeContext);
    const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
    const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
    const constraintsRef = useRef(null);
    return (<>
			<motion.aside style={asideStyle} className={classNames('aside', { open: asideStatus }, {
            'aside-touch-bar': hasTouchButton && isModernDesign,
            'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
            'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
        })}>
				{children}
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (<>
					<motion.div className='aside-drag-area' ref={constraintsRef}/>
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div className='aside-touch' drag='x' whileDrag={{ scale: 1.2 }} whileHover={{ scale: 1.1 }} dragConstraints={constraintsRef} 
        // onDrag={(event, info) => console.log(info.point.x, info.point.y)}
        dragElastic={0.1} style={{ x, zIndex: 1039 }} onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}/>
					</Tooltips>
				</>)}
		</>);
};
export default Aside;
