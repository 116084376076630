import React from 'react';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../layout/Header/Header';
import MekaHeaderRight from './MekaHeaderRight';
import useDarkMode from '../hooks/useDarkMode';
const MekaHeader = (props) => {
    const { darkModeStatus } = useDarkMode();
    return (<Header>
        <HeaderLeft>
            <div className='d-flex align-items-center'>
                <div className='row g-4'>
                    <div className='col-md-auto'>
                        <div className={classNames('fs-3', 'fw-bold', {
                            'text-dark': !darkModeStatus,
                        })}>
                            {props.text}
                        </div>
                    </div>
                </div>
            </div>
        </HeaderLeft>
        <MekaHeaderRight />
    </Header>);
};
export default MekaHeader;