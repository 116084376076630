import {setLogUser} from './contexts/authContext';
import showNotification from './components/extras/showNotification';

const Meka = class {
    empty = (object) => {
        if (typeof object === "object")
            return Object.keys(object).length === 0;
        return object.length === 0;
    };
    read = (object = null, prop = null, prop2 = "") => {
        try {
            const str = !Array.isArray(prop) ? object[prop] : object[prop[0]][prop[1]];
            return typeof str === "undefined" ? prop2 : str;
        } catch (e) {
            return prop2;
        }
    };
    ucfirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    bag = {
        logout: {time: (new Date()).getTime(), status: true}
    };
    root = document.getElementById("root");
    base = document.getElementById("root").attributes["base-url"].nodeValue;
    sub = document.getElementById("root").attributes["sub-url"].nodeValue;
    response = "";
    xhttp = new XMLHttpRequest();
    url = "";
    data = "";
    request = (url, data = null) => {
        this.xhttp = new XMLHttpRequest();
        this.url = `${this.base}/api/${url}`;
        this.data = data;
        var res = "";
        this.response = res;
        return this;
    };
    do = () => {
        var that=this;
        return new Promise(function (resolve, reject) {
            var res = "";
            that.xhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    res = JSON.parse(this.responseText);
                    resolve(res)
                }
                if (this.status == 500) {
                }
            };
            that.xhttp.upload.addEventListener("progress", function (evt) {

                var percentComplete = evt.loaded / evt.total;
                percentComplete = parseInt(percentComplete * 100);
                if(window.progressbar)
                    window.progressbar(percentComplete);
                if (percentComplete === 100) {

                }

                //}
            });
            that.xhttp.open((that.data === null ? "GET" : "POST"), that.url, true);
            that.xhttp.withCredentials = true;
            if (that.data === null) that.xhttp.send(); else that.xhttp.send(that.data);

        });
    };
    done = async function (call) {
        var res = await this.do();
        if (res.status == "OK") {
            call(res);
        }
        if (res.status == "FAIL" && this.read(res.bag, "code") == 101) {
            setLogUser("");
            this.bag.logoutTime = (new Date()).getTime();
        }
        if (res.status == "FAIL" && this.read(res.bag, "code") != 101) {
            showNotification('Error',res.errors[0], "Danger");
        }
        return this;
    };
    fail = function (call) {
        if (response.status == "FAIL") {
            call(this.response);
        }
        return this;
    };
};
const meka = new Meka();
export default meka;