import React from 'react';
const Svg3K = (props) => {
    return (<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z'/>
			<path d='M5 19h14V5H5v14zm8-10h1.5v2.25L16.25 9H18l-2.25 3L18 15h-1.75l-1.75-2.25V15H13V9zm-6.5 4.5h3v-1h-2v-1h2v-1h-3V9H10c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1H6.5v-1.5z' opacity={0.3}/>
			<path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z'/>
			<path d='M11 14v-4c0-.55-.45-1-1-1H6.5v1.5h3v1h-2v1h2v1h-3V15H10c.55 0 1-.45 1-1zM14.5 12.75L16.25 15H18l-2.25-3L18 9h-1.75l-1.75 2.25V9H13v6h1.5z'/>
		</svg>);
};
export default Svg3K;
