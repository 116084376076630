import React, { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername } from '../common/data/userDummyData';
import meka from "../meka";
const AuthContext = createContext({});
var setLogUser;
export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState( '');//JSON.parse(localStorage.getItem('mekaUser')) ||
    setLogUser=setUser;
    const [userData, setUserData] = useState({});
    useEffect(() => {
        meka.request("content").done((res) => {
            setUser(res.result.user??"");
        });
    }, []);

    const value = useMemo(() => ({
        user,
        setUser,
        userData,
    }), [user, userData]);
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
export {setLogUser};
export default AuthContext;
