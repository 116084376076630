import React from 'react';
import { demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import MekaAside from '../pages/MekaAside';
const asides = [
    { path: "/signon", element: null },
    { path: "/signup", element: null },
    { path: pageLayoutTypesPagesMenu.blank.path, element: null },
    { path: '*', element: <MekaAside /> },
];
export default asides;
