import React, { lazy } from 'react';
import { componentPagesMenu, dashboardPagesMenu, demoPagesMenu, gettingStartedPagesMenu, pageLayoutTypesPagesMenu, } from '../menu';
import Login from '../pages/MekaLogin';
const NODES={
    GROUP:lazy(() => import('../pages/GroupList')),
    MONITOREDIT:lazy(() => import('../pages/MonitorEdit')),
    TRACK:lazy(() => import('../pages/Track')),
    PLAYLIST:lazy(() => import('../pages/Playlist')),
    USER:lazy(() => import('../pages/UserList')),
    USERSAVE:lazy(() => import('../pages/UserSave')),
    PROFILE:lazy(() => import('../pages/UserSave')),
};
const presentation = [
    {
        path: "/group",
        element: <NODES.GROUP />,
    },
    {
        path: "/monitor/edit/:id",
        element: <NODES.MONITOREDIT />,
    },
    {
        path: "/playlist",
        element: <NODES.PLAYLIST />,
    },
    {
        path: "/profile",
        element: <NODES.USERSAVE isProfile={true} />,
    },
    {
        path: "/user",
        element: <NODES.USER />,
    },
    {
        path: "/user/new",
        element: <NODES.USERSAVE isNew={true} />,
    },
    {
        path: "/user/edit/:id",
        element: <NODES.USERSAVE />,
    },
    {
        path: "/track",
        element: <NODES.TRACK />,
    },
    {
        path: "/signon",
        element: <Login />,
    },
    {
        path: "/",
        element: <NODES.USERSAVE isProfile={true} />,
    }
];

const contents = [...presentation];// , ...documentation
export default contents;
