import React,{Suspense} from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
const LOADING=(<div></div>);
const children = (<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<React.StrictMode>
					<Suspense fallback={LOADING}>
					<App />
					</Suspense>
				</React.StrictMode>
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>);
const container = document.getElementById('root');
// ReactDOM.render(children, container); // For React 17
createRoot(container).render(children); // For React 18
