import React, { useState, useContext } from 'react';
import { useNavigate,Link,NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import { demoPagesMenu } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import ThemeContext from '../../contexts/themeContext';
import meka from '../../meka';
const User = () => {
    const { width } = useWindowSize();
    const { setAsideStatus } = useContext(ThemeContext);
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleItem = useNavigationItemHandle();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const [collapseStatus, setCollapseStatus] = useState(false);
    const { t } = useTranslation(['translation', 'menu']);
    return (<>
			<div className={classNames('user', { open: collapseStatus })} role='presentation' onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img src={meka.base+"/uploads/"+user?.avatar} alt='Avatar' width={128} height={128}/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{`${user?.name}`}
						<Icon icon='Verified' className='ms-1' color='info'/>
					</div>
					<div className='user-sub-title'>{user?.role===2?"Customer":"Admin"}</div>
				</div>
			</div>

			<Collapse isOpen={true} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<NavLink to={"/profile"}>
						<div role='presentation' className='navigation-item cursor-pointer'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon'/>
									<span style={{textDecoration:"none"}}>
										Profile
									</span>
								</span>
							</span>
						</div>
						</NavLink>
						<div role='presentation' className='navigation-item cursor-pointer' onClick={() => {
            setDarkModeStatus(!darkModeStatus);
            handleItem();
        }}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon={darkModeStatus ? 'DarkMode' : 'LightMode'} color={darkModeStatus ? 'info' : 'warning'} className='navigation-icon'/>
									<span className='navigation-text'>
										{darkModeStatus
            ? t('menu:DarkMode')
            : t('menu:LightMode')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div role='presentation' className='navigation-item cursor-pointer' onClick={() => {
							meka.request("signout").done(res=>{
								if (setUser) {
									setUser('');
								}
								if (width < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
									setAsideStatus(false);
								}
								navigate(`../signon`);
							});
        				}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon'/>
									<span className='navigation-text'>
										{t('menu:Logout')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>);
};
export default User;
