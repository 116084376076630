import { Store } from 'react-notifications-component';
const showNotification = (title, message, type = 'default') => {
    Store.addNotification({
        title,
        message,
        // @ts-ignore
        type,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
            duration: 5000,
            pauseOnHover: true,
            onScreen: true,
            showIcon: true,
            waitForAnimation: true,
        },
    });
};
export default showNotification;
