import React from 'react';
import { useMeasure } from 'react-use';
const Footer = ({ children }) => {
    const [ref, { height }] = useMeasure();
    const root = document.documentElement;
    root.style.setProperty('--footer-height', `${height}px`);
    return (<footer ref={ref} className='footer'>
			{children}
		</footer>);
};
export default Footer;
