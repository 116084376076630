import React from 'react';
const SvgReplay = (props) => {
    return (<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<g fill='none'>
				<path d='M0 0h24v24H0z'/>
				<path d='M0 0h24v24H0z'/>
				<path d='M0 0h24v24H0z'/>
			</g>
			<path d='M7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8V1L7 6z'/>
		</svg>);
};
export default SvgReplay;
