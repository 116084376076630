import React from 'react';
import PropTypes from 'prop-types';
import meka from "../meka";
const Logo = ({ width, height }) => {
    return (<img src={meka.base+"/static/logo.png"} style={{width:"150px",height:"auto",marginBottom:"30px"}}/>);
};
Logo.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
};
Logo.defaultProps = {
    width: 2155,
    height: 854,
};
export default Logo;
