import React from 'react';
const SvgReceiptLong = (props) => {
    return (<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z' opacity={0.3}/>
			<path d='M0 0h24v24H0V0z' fill='none'/>
			<path d='M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2l-1.5 1.5zM19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z'/>
			<path d='M9 7h6v2H9zM16 7h2v2h-2zM9 10h6v2H9zM16 10h2v2h-2z'/>
		</svg>);
};
export default SvgReceiptLong;
