import React from 'react';
import { componentPagesMenu, dashboardPagesMenu, demoPagesMenu, gettingStartedPagesMenu, pageLayoutTypesPagesMenu, } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import MekaHeader from '../pages/MekaHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import DocumentationHeader from '../pages/_layout/_headers/DocumentationHeader';
const headers = [
    { path:"/group",element:<MekaHeader text="Groups List" />},
    { path:"/monitor/edit/:id",element:<MekaHeader text="Monitor Edit" />},
    { path:"/track",element:<MekaHeader text="Tracks List" />},
    { path:"/playlist",element:<MekaHeader text="Playlists List" />},
    { path: "/profile", element: <MekaHeader text="Profile" /> },
    { path: "/user", element: <MekaHeader text="Users List" /> },
    { path: "/user/new", element: <MekaHeader text="User New" /> },
    { path: "/user/edit/:id", element: <MekaHeader text="User Edit" /> },
    { path: "/signon", element: null },
    { path: "/signup", element: null },
    { path: "/", element: <MekaHeader text="Profile" /> }
];
export default headers;
