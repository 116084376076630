import React, { useCallback, useContext, useState,useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Card, { CardBody } from '../components/bootstrap/Card';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Input from '../components/bootstrap/forms/Input';
import Button from '../components/bootstrap/Button';
import Logo from '../components/Logo';
import useDarkMode from '../hooks/useDarkMode';
import AuthContext from '../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../common/data/userDummyData';
import Spinner from '../components/bootstrap/Spinner';
import Alert from '../components/bootstrap/Alert';
import meka from "../meka";
const LoginHeader = ({ isNewUser }) => {
    if (isNewUser) {
        return (<>
            <div className='text-center h1 fw-bold mt-5'>Create Account,</div>
            <div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
        </>);
    }
    return (<>
        <div className='text-center h1 fw-bold mt-5'>Welcome,</div>
        <div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
    </>);
};
LoginHeader.defaultProps = {
    isNewUser: false,
};
const Login = ({ isSignUp }) => {
    const { setUser } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [signInPassword, setSignInPassword] = useState(false);
    const [singUpStatus, setSingUpStatus] = useState(!!isSignUp);
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/'), [navigate]);

    const emailRef=useRef(null);
    const passRef=useRef(null);
    const submit= () => {
        var body = new FormData();
        body.append("email",emailRef.current.value);
        body.append("password",passRef.current.value);
        meka.request("signon",body).done((res)=>{
            setUser(res.result);
            handleOnClick();
        });
    };
    const [isLoading, setIsLoading] = useState(false);
    const handleContinue = () => {
        setIsLoading(true);
        setTimeout(() => {
            setSignInPassword(true);
            setIsLoading(false);
        }, 1000);
    };
    return (<PageWrapper isProtected={false} title={"RecMan Display"} className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
        <Page className='p-0'>
            <div className='row h-100 align-items-center justify-content-center'>
                <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                    <Card className='shadow-3d-dark' data-tour='login-page'>
                        <CardBody>
                            <div className='text-center my-5'>
                                <Link to='/' className={classNames('text-decoration-none  fw-bold display-2', {
                                    'text-dark': !darkModeStatus,
                                    'text-light': darkModeStatus,
                                })}>
                                    <img src={meka.base+"/static/logo2.png"} style={{width:"200px",height:"auto"}}/>
                                </Link>
                            </div>
                            <div className={classNames('rounded-3', {
                                'bg-l10-dark': !darkModeStatus,
                                'bg-dark': darkModeStatus,
                            })}>
                            </div>

                            <LoginHeader isNewUser={singUpStatus}/>

                            <form className='row g-4'>

                                    <div className='col-12'>
                                        <FormGroup id='loginUsername' isFloating label='Your email or username' className={classNames({
                                            'd-none': signInPassword,
                                        })}>
                                            <Input ref={emailRef} autoComplete='username' name="email"/>
                                        </FormGroup>
                                        <FormGroup id='loginPassword' isFloating label='Password' className={classNames({
                                            'd-none': !signInPassword,
                                        })}>
                                            <Input ref={passRef} type='password' autoComplete='current-password' name="password"/>
                                        </FormGroup>
                                    </div>
                                    <div className='col-12'>
                                        {!signInPassword ? (<Button color='warning' className='w-100 py-3' onClick={handleContinue}>
                                            {isLoading && (<Spinner isSmall inButton isGrow/>)}
                                            Continue
                                        </Button>) : (<Button color='warning' className='w-100 py-3' onClick={e=>submit()}>
                                            Login
                                        </Button>)}
                                    </div>
                            </form>
                        </CardBody>
                    </Card>
                    <div className='text-center'>
                        <a href='/' className={classNames('text-decoration-none me-3', {
                            'link-light': singUpStatus,
                            'link-dark': !singUpStatus,
                        })}>
                            Privacy policy
                        </a>
                        <a href='/' className={classNames('link-light text-decoration-none', {
                            'link-light': singUpStatus,
                            'link-dark': !singUpStatus,
                        })}>
                            Terms of use
                        </a>
                    </div>
                </div>
            </div>
        </Page>
    </PageWrapper>);
};
Login.propTypes = {
    isSignUp: PropTypes.bool,
};
Login.defaultProps = {
    isSignUp: false,
};
export default Login;
