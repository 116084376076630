import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import List from './List';
export const NavigationLine = ({ className }) => {
    return <hr className={classNames('navigation-line', className)}/>;
};
NavigationLine.propTypes = {
    className: PropTypes.string,
};
NavigationLine.defaultProps = {
    className: undefined,
};
export const NavigationTitle = ({ className, children, ...props }) => {
    return (<li className='navigation-item'>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<span className={classNames('navigation-title', className)} {...props}>
				{children}
			</span>
		</li>);
};
NavigationTitle.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
NavigationTitle.defaultProps = {
    className: undefined,
};
const Navigation = forwardRef(({ menu, horizontal, id, className, ...props }, ref) => {
    const [activeItem, setActiveItem] = useState(undefined);
    const { t } = useTranslation('menu');
    function fillMenu(data, parentId, rootId, isHorizontal, isMore) {
        return Object.keys(data).map((item) => data[item].path ? (<Item key={data[item].id} rootId={rootId} id={data[item].id} title={data[item].text} icon={data[item].icon} to={`${data[item].path}`} parentId={parentId} isHorizontal={isHorizontal} setActiveItem={setActiveItem} activeItem={activeItem} notification={data[item].notification} hide={data[item].hide}>
						{!!data[item].subMenu &&
                fillMenu(data[item].subMenu, data[item].id, rootId, isHorizontal, undefined)}
					</Item>) : (!isMore &&
            !isHorizontal && (<NavigationTitle key={data[item].id}>
							{t(data[item].text)}
						</NavigationTitle>)));
    }
    return (
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    <nav ref={ref} aria-label={id} className={className} {...props}>
				<List id={id} horizontal={horizontal}>
					{fillMenu(menu, id, id, horizontal, undefined)}
					{horizontal && (<Item rootId={`other-${id}`} title={t('More')} icon='MoreHoriz' isHorizontal isMore>
							{fillMenu(menu, `other-${id}`, `other-${id}`, false, true)}
						</Item>)}
				</List>
			</nav>);
});
Navigation.displayName = 'Navigation';
Navigation.propTypes = {
    horizontal: PropTypes.bool,
    // @ts-ignore
    menu: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        path: PropTypes.string,
        icon: PropTypes.string,
        isDisable: PropTypes.bool,
        subMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            text: PropTypes.string,
            path: PropTypes.string,
            icon: PropTypes.string,
            isDisable: PropTypes.bool,
        })),
    }).isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
};
Navigation.defaultProps = {
    horizontal: false,
    className: undefined,
};
export default Navigation;
