import React from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
const DefaultFooter = () => {
    const { darkModeStatus } = useDarkMode();
    return (<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light'>Copyright © 2023 RecMan Display</span>
					</div>
					<div className='col-auto'>
						<a href='/' className={classNames('text-decoration-none', {
            'link-dark': !darkModeStatus,
            'link-light': darkModeStatus,
        })}>
							<small className='fw-bold'>Developed by <a target={"_blank"} href={"https://mekait.com/"}>Mekait</a></small>
						</a>
					</div>
				</div>
			</div>
		</Footer>);
};
export default DefaultFooter;
