import React, { useContext, useState, useEffect,Suspense } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../layout/Navigation/Navigation';
import User from '../layout/User/User';
import meka from "../meka";
import { componentPagesMenu, dashboardPagesMenu, demoPagesMenu, gettingStartedPagesMenu, pageLayoutTypesPagesMenu, } from '../menu';
import ThemeContext from '../contexts/themeContext';
import AuthContext from '../contexts/authContext';
import Card, { CardBody } from '../components/bootstrap/Card';
import Hand from '../assets/img/hand.png';
import HandWebp from '../assets/img/hand.webp';
import Icon from '../components/icon/Icon';
import Button from '../components/bootstrap/Button';
import useDarkMode from '../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../layout/Aside/Aside';
const LOADING=(<div></div>);
const MekaAside = () => {
    const { asideStatus, setAsideStatus } = useContext(ThemeContext);
    const [doc, setDoc] = useState(localStorage.getItem('facit_asideDocStatus') === 'true' || false);
    const { t } = useTranslation(['translation', 'menu']);
    const { darkModeStatus } = useDarkMode();
    const [mainMenu, setMainMenu] = useState({});
    const { user, setUser } = useContext(AuthContext);
    const [rand,setRand]=useState(0);
    useEffect(() => {
        meka.request("content").done((res) => {
            setMainMenu(res.result.menu);
        });
    }, []);
    return (
        <Aside>
        <AsideHead>
            <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
        </AsideHead>
        <AsideBody>
            <Navigation menu={mainMenu} id='aside-main'/>
            <NavigationLine />
        </AsideBody>
        <AsideFoot>
            <User />
        </AsideFoot>
        </Aside>);
};
export default MekaAside;
